$link-color: #0164AD;

.blue-btn {
  background: #0164AD;
  color: #fff;
  padding: 3px 10px;
  margin: 3px;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gray-btn {
  background: #6C757D;
  color: #fff;
  padding: 3px 10px;
  margin: 3px;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toolbar-btn {
  background: #272C2E;
  color: #fff;
  padding: 3px 10px;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.xplane-btn {
  background: #24466F;
  color: #fff;
  padding: 3px 10px;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}